<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </router-link>
          </li>
          <li>
            <span> {{
              this.$t('report.company_dashboard.reports') }}
            </span>
          </li>
          <li>Rapor İstatistikleri</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span>Rapor İstatistikleri </span>
        </h2>
      </div>
    </div>
    <!--Modal Profie-->

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <!-- <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center"> -->

      <body style="margin:0px;padding:0px;overflow:hidden">
        <iframe src="https://raporyeni.bek.org.tr/public/showcases/SC-8884788F19D7C9E" class="uk-padding-remove" height="100%" width="100%"
          frameborder="0"></iframe>
      </body>
      <!-- </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import reports, {
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
} from "@/core/services/store/reports.module";

import store from "@/core/services";
import moment from "moment";
import axios from "axios";
import Pagination from "@/assets/components/Pagination";
import UserProfile from "@/view/company/User/UserProfile";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "ReportStatistics",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, reports);
  },
  components: { Pagination, UserProfile, DefaultLoading },
  data() {
    return {
      pagination: null,
      activeItem: null,
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      formattedItems: [],
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      successMessage: "",
    };
  },
  computed: {
    items: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    user_loading: {
      get() {
        return store.getters[_MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
  },
  methods: {
    exportGifts() {
      axios
        .get(process.env.VUE_APP_BACKEND_APP_URL + "/api/report/export/gifts", {
          responseType: "blob",
          params: {
            sort: this.filter.sort,
            order: this.filter.order,
            like: this.filter.search,
          },
          headers: this.apiHeaders,
        })
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let day = moment().format("YYYYMMDDHHMMSS");
          let fileName = "gift_report_list_" + day + ".xlsx";
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    filterResult(filter = null) {
      let self = this;
      let filters = {
        like: self.filter.search,
        page: self.page,
        per_page: self.per_page,
        sort: self.filter.sort,
        order: self.filter.order,
      };
      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/report/gifts",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {
            let results = [];
            let items = response.data;
            this.pagination = items;
            items.data.forEach((item) => {
              results.push({
                id: item.id,
                assignment_name: item.giftable
                  ? item.giftable.name
                  : "Satın Alınan Hediye",
                photo_link: item.gift.image_link,
                gift_name: item.gift.name,
                gift_score: item.gift.score,
                count_item: item.count_item != 0 ? item.count_item : "1",
              });
            });
            this.formattedItems = results;
          }
        });
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getFilter(value) {
      this.page = 1;
      this.filterResult(value);
    },
  },
  mounted() {
    if (!this.$isGiftVisible) {
      this.$router.push({ name: "company.dashboard" });
    }
    this.filterResult();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);

    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    formattedInstitution(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker1").selectpicker();
        }, 200);
      }
    },
    formattedUserGroup(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker2").selectpicker();
        }, 200);
      }
    },
    formattedPosition(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker3")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedTitle(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker4")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedRegion(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker5")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedDepartment(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker6")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedBranch(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker7")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    activeItem(value) {
      if (value) {
        this.getUserDetail(value.id);
      }
    },
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
};
</script>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
